@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Plus+Jakarta+Sans:wght@300&family=Poppins&display=swap');

:root {
    --font-base: 'Playfair Display', serif;
    --font-alt: 'Plus Jakarta Sans', sans-serif;
    --font-popins: 'Poppins', sans-serif;
    --color-navy : #094B72;
    --color-gray: #4F4F4F;
    --color-light-gray: #8B8C8C;
    --color-dark-gray: #2D2F30;
    --padding-section: 4rem 6rem;
}

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

a {
    color: unset;
    text-decoration: none;
}