.app__navbar{
    padding: 2rem 6rem 4rem 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    height: 100px;
}


.navbar__logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-popins);
    cursor:default;
}

.logo__letter{
    height: 36px;
    width: 36px;
    background: var(--color-navy);
    color: #fff;
    border-radius: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.logo__text{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: var(--color-navy);
    padding-left: 1rem;
    letter-spacing: 1px;
}

.navbar__links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    flex: 0.8;
}

.navbar__links-text{
    margin: 0 1rem;
    text-transform: uppercase;
    font-family: var(--font-popins);
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 1.5px;
    color: var(--color-gray);
}

.navbar__links-text:hover {
    color: var(--color-light-gray);
    border-bottom: 1px solid var(--color-light-gray);
    margin-bottom: -1px;
}

.navbar-smallscreen{
    display: none;
    
}

.navbar-smallscreen_overlay{
    position:fixed;
    top:0;
    right: -201px;
    height: 100vh;
    width: 200px;
    background-color: #F2F7FF;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 2rem;
    z-index: 1000;
}

.smallscreen_overlay__links {
    margin-top: 1rem;
    list-style: none;
    width: 100%;
}

.smallscreen_overlay__links > .navbar__links-text{
    margin: 1rem 0;
}

.smallscreen_overlay__links > .navbar__links-text:hover {
    margin-bottom: -1px;
}

@media screen and (max-width: 1150px) {
    .app__navbar{
        padding: 2rem 3rem;
    }
}

@media screen and (max-width: 700px) {
    
    .navbar__links {
        display: none;
    }

    .navbar-smallscreen{
        display: flex;
    }

    .navbar__search{
        flex: 0.7;
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 450px){
    .app__navbar{
        padding: 0 1rem;
        height: 80px;
    }
}

.slide-left {
	-webkit-animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-200px);
              transform: translateX(-200px);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-200px);
              transform: translateX(-200px);
    }
  }

  svg{
    cursor: pointer;
  }
  





