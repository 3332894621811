.app__achievement{
    padding: var(--padding-section);
    background-image: url(../../assets/backgroundBlue.png);
    background-repeat: no-repeat;
    background-size:cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__achievement-title{
    font-family: var(--font-base);
    color: #fff;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    letter-spacing: 0.15rem;
    font-weight: 900;
}

.app__achievement-text{
    font-family: var(--font-popins);
    color: #fff;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: 200;
    margin-top: 1rem;
}

.achievement__counts{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}

.counts__onecount{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    flex: 1;
    text-align: center;
}

.counts__onecount:nth-child(2){
    border-left: 1px solid rgb(255, 255, 255, 0.4);
    border-right: 1px solid rgb(255, 255, 255, 0.4);
}

.counts__onecount-number{
    font-family: var(--font-popins);
    color: #fff;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    
}

.counts__onecount-alt{
    font-family: var(--font-popins);
    color: #fff;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: 0.05rem;
}

@media screen and (max-width: 1050px){
    .app__achievement{
        padding: 2rem 3rem;
    }
    
}

@media screen and (max-width: 650px){
    .app__achievement{
        padding: 2rem 1rem;
    }
}

@media screen and (max-width: 600px){
    .achievement__counts{
        flex-direction: column;
    }

    .counts__onecount{
        margin: 0;
        padding: 1rem 0;
    }

    .counts__onecount:nth-child(1){
        margin-top: 2rem;
    }

    .counts__onecount:nth-child(2){
        border: none;
        border-top: 1px solid rgb(255, 255, 255, 0.4);
        border-bottom: 1px solid rgb(255, 255, 255, 0.4);
    }
}

@media screen and (max-width: 400px){
    .app__achievement-title{
        font-size: 38px;
        line-height: 54px;
        
    }
}