.app__cta{
    padding: var(--padding-section);
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__cta-wrapper{
    display: flex;
    flex-direction: row;
    max-width: 1450px;
}

.cta__content-title{
    font-family: var(--font-base);
    font-weight: 900;
    color: var(--color-dark-gray);
    font-size: 48px;
    line-height: 64px;
}

.cta__content-text{
    font-family: var(--font-popins);
    color: var(--color-gray);
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05rem;
    margin-top: 2rem;
}

.cta__content-button{
    font-family: var(--font-popins);
    font-weight: 600;
    color: #fff;
    font-size: 22px;
    line-height: 36px;
    background-color: var(--color-navy);
    padding: 1rem 2rem;
    border-radius: 8px;
    border:none;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    cursor: pointer;
}

.cta__content-button > svg {
    margin-right: 0.5rem;
}

.cta__image{
    margin-left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cta__image > img {
    width: fit-content;
}

@media screen and (max-width: 1050px){
    .app__cta{
        padding: 2rem 3rem;
    }

    .cta__content-title{
        font-size: 40px;
        line-height: 56px;
    }
    
    .cta__content-text{
        font-size: 20px;
        line-height: 32px;
    }
    
    .cta__content-button{
        font-size: 18px;
        line-height: 30px;
        padding: 0.5rem 1rem;
    }
}

@media screen and (max-width: 850px){
    .cta__content{
        flex:1;
    }

    .cta__image{
        flex: 0.7;
    }

    .cta__image > img{
        width: 100%;
    }
}

@media screen and (max-width: 650px){
    .app__cta{
        padding: 2rem 2rem;
    }

    .app__cta-wrapper{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .cta__content-button{
        margin:auto;
        margin-top: 2rem;
    }


    .cta__image{
        width: 100%;
        margin: 0;
        margin-top: 2rem;
    }
}


